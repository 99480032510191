import type { Strapi4RequestParams } from '~/types/strapi'

export function useApiUser() {
  const client = useStrapiClient()
  const { executeRecaptcha } = useNuxtApp()?.$recaptcha

  async function updateUser(
    id: number | string,
    body: any,
    query: Strapi4RequestParams = {},
  ) {
    const { data } = await client(`/users/${id}`, {
      method: 'PUT',
      body,
      params: {
        ...query,
      },
    })
    return data
  }

  async function checkIfEmailExists(email: any): Promise<boolean> {
    return await client('/user/check-if-email-exists', {
      method: 'POST',
      body: {
        email,
        recaptchaToken: await executeRecaptcha('checkIfEmailExists'),
      },
    })
  }

  return {
    updateUser,
    checkIfEmailExists,
  }
}
